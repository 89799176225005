import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Box, Divider } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { headerHeight } from "../../constants/app";
import { getSinglePage } from "./async-actions";
import { SingleMemorialPageSelectors } from "./selectors";
import { useAppDispatch } from "../../store/hooks";
import { Language } from "../../translations/i18n";
import { extractTextByLanguage } from "../../utils/functions";

import Section from "../../components/memorial-page/section";
import PersonalMemorialHeader from "../../components/memorial-page/PersonalMemorialHeader";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import ShareButtons from "../../components/memorial-page/ShareButtons";
import NovaButton from "../../components/shared/NovaButton";
import Tribute from "../memorial-page/Tribute";
import Loader from "../../components/Loader";
import Gallery from "../memorial-page/gallery";
import Tree from "../../components/tree/tree";
import MarkdownEditor from "../../components/md-editor/markdown-editor";
import FilesList from "../memorial-page/FilesList";
import TranslatedText from "../../components/translated-text";

function Page() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { t } = useTranslation();
   const { pageId } = useParams();

   const memorialHeaderComponentRef = useRef<HTMLElement>(null);
   const biographySectionRef = useRef<HTMLDivElement>(null);
   const photosSectionRef = useRef<HTMLDivElement>(null);
   const familyTreeSectionRef = useRef<HTMLDivElement>(null);
   const tributesSectionRef = useRef<HTMLDivElement>(null);

   const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
   const [selectedPhotoGalleryId, setSelectedPhotoGalleryId] = useState<number>();
   const [selectedTributesGalleryId, setSelectedTributesGalleryId] = useState<number>();
   const [isScrolled, setIsScrolled] = useState<boolean>(false);
   const [photoGalleryIsOpen, setPhotoGalleryIsOpen] = useState<boolean>(false);
   const [tributesGalleryIsOpen, setTributesGalleryIsOpen] = useState<boolean>(false);

   const memorialPageData = useSelector(SingleMemorialPageSelectors.getSinglePage);

   const onChangeTab = useCallback((index: number, id: string) => {
      setActiveTabIndex(index);

      window.document.getElementById(id)?.scrollIntoView({
         behavior: "smooth",
      });
   }, []);
   const onClickPhoto = useCallback((index: number) => {
      setSelectedPhotoGalleryId(index);
      setPhotoGalleryIsOpen(true);
   }, []);
   const onClickSeeAllPhotos = useCallback(() => {
      setSelectedPhotoGalleryId(undefined);
      setPhotoGalleryIsOpen(true);
   }, []);
   const onClickTribute = useCallback((index: number) => {
      setSelectedTributesGalleryId(index);
      setTributesGalleryIsOpen(true);
   }, []);
   const handleCloseGallery = useCallback(() => {
      setPhotoGalleryIsOpen(false);
      setTributesGalleryIsOpen(false);
   }, []);
   const onScroll = useCallback(() => {
      if (!memorialPageData) return;

      //? Tabs position
      const headerOffsetTop = memorialHeaderComponentRef.current?.offsetTop ?? 0;
      const headerOffsetHeight = memorialHeaderComponentRef.current?.offsetHeight ?? 0;
      const pageScrollY = window.scrollY + (headerHeight - 1);

      if (pageScrollY >= headerOffsetTop + headerOffsetHeight) setIsScrolled(true);
      else setIsScrolled(false);

      //? Tabs active index
      const biographySectionOffsetTop = biographySectionRef.current?.offsetTop ?? 0;
      const photosSectionOffsetTop = photosSectionRef.current?.offsetTop ?? 0;
      const familyTreeSectionOffsetTop = familyTreeSectionRef.current?.offsetTop ?? 0;
      const tributesSectionOffsetTop = tributesSectionRef.current?.offsetTop ?? 0;

      if (pageScrollY >= tributesSectionOffsetTop - 100)
         setActiveTabIndex(memorialPageData?.isMonumentum ? 3 : 4);
      else if (
         !memorialPageData?.isMonumentum &&
         pageScrollY >= familyTreeSectionOffsetTop - 100
      )
         setActiveTabIndex(3);
      else if (pageScrollY >= photosSectionOffsetTop - 100) setActiveTabIndex(2);
      else if (pageScrollY >= biographySectionOffsetTop - 100) setActiveTabIndex(1);
      else setActiveTabIndex(0);
   }, [memorialPageData]);

   const tabs = useMemo(
      () => [
         {
            id: "all",
            text: <TranslatedText text="personal-memorial-page.tabs.all" />,
         },
         {
            id: "biography",
            text: memorialPageData?.isMonumentum ? (
               <TranslatedText text="personal-memorial-page.tabs.information" />
            ) : (
               <TranslatedText text="personal-memorial-page.tabs.biography" />
            ),
         },
         {
            id: "photos",
            text: <TranslatedText text="personal-memorial-page.tabs.photos" />,
         },
         ...(!memorialPageData?.isMonumentum
            ? [
                 {
                    id: "familyTree",
                    text: (
                       <TranslatedText text="personal-memorial-page.tabs.family-tree" />
                    ),
                 },
              ]
            : []),
         {
            id: "tributes",
            text: <TranslatedText text="personal-memorial-page.tabs.tributes" />,
         },
      ],
      [memorialPageData],
   );

   useEffect(() => {
      window.addEventListener("scroll", onScroll);
      onScroll();

      return () => {
         window.removeEventListener("scroll", onScroll);
      };
   }, [onScroll]);

   useEffect(() => {
      dispatch(getSinglePage(pageId));

      window.scrollTo(0, 0);
   }, [dispatch, pageId]);

   return (
      <>
         <Box
            sx={{
               background: "#F3F5F7",
            }}
         >
            {memorialPageData ? (
               <>
                  <Box
                     sx={{
                        paddingTop: {
                           xs: "20px",
                           sm: "60px",
                        },
                     }}
                     ref={memorialHeaderComponentRef}
                  >
                     {memorialPageData.isMine &&
                        !memorialPageData.hasMedallions &&
                        memorialPageData.subscription?.subscriptionPlan?.type === 2 &&
                        memorialPageData.deathDay && (
                           <Box
                              sx={{
                                 marginBottom: "20px",
                              }}
                           >
                              <Section
                                 backgroundColor="#1b1b45"
                                 paddingBlock="10px"
                                 paddingInline="20px"
                              >
                                 <Box
                                    sx={{
                                       display: "flex",
                                       width: "100%",
                                       flexDirection: "row",
                                       alignItems: "center",
                                       gap: "10px",
                                    }}
                                 >
                                    <AutoAwesomeIcon
                                       sx={{
                                          color: "#ffffff",
                                          fontSize: "26px",
                                       }}
                                    />
                                    <NovaText
                                       type={NovaTextTypes.BaseNormal}
                                       style={{
                                          flex: 1,
                                          fontWeight: "500",
                                          color: "#ffffff",
                                       }}
                                    >
                                       You have one FREE medallion to use!
                                    </NovaText>
                                    <NovaButton
                                       style={{
                                          fontSize: "14px",
                                          color: "#1b1b45",
                                          backgroundColor: "#E4CB8D",
                                          borderRadius: "32px",
                                          padding: "6px 20px",
                                          ":hover": {
                                             color: "#1b1b45",
                                             backgroundColor: "#d3ba7c",
                                          },
                                       }}
                                       onClick={() => navigate("../products")}
                                    >
                                       Use it now
                                    </NovaButton>
                                 </Box>
                              </Section>
                           </Box>
                        )}

                     <Section
                        id="all"
                        borderBottomLeftRadius="0px"
                        borderBottomRightRadius="0px"
                     >
                        <PersonalMemorialHeader
                           account={memorialPageData}
                           isMine={memorialPageData.isMine}
                           isMonumentum={memorialPageData?.isMonumentum}
                           pageId={parseInt(pageId ?? "")}
                        />
                     </Section>
                  </Box>

                  <Box
                     className={isScrolled ? "stickyTabsHeader" : ""}
                     sx={{
                        display: "flex",
                        position: "sticky",
                        top: `${headerHeight - 1}px`,
                        width: "100%",
                        maxWidth: "1100px",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "40px",
                        backgroundColor: "#ffffff",
                        borderTop: "1px solid #E9DDC8",
                        borderBottomLeftRadius: "24px",
                        borderBottomRightRadius: "24px",
                        margin: "0 auto",
                        padding: {
                           xs: "10px 20px",
                           sm: "16px 60px",
                           lg: isScrolled ? "16px 24px" : "16px 104px",
                        },
                        overflowX: "auto",
                        zIndex: 10,
                     }}
                  >
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "row",
                           gap: "10px",
                           whiteSpace: "nowrap",
                        }}
                     >
                        {tabs.map((tab, index) => (
                           <Box
                              sx={{
                                 borderRadius: "999px",
                                 color: activeTabIndex === index ? "#ffffff" : "#777E90",
                                 backgroundColor:
                                    activeTabIndex === index ? "#1B1B48" : "transparent",
                                 padding: "10px 20px",
                                 cursor: "pointer",
                              }}
                              onClick={() => onChangeTab(index, tab.id)}
                              key={tab.id}
                           >
                              <NovaText
                                 type={NovaTextTypes.BaseNormal}
                                 style={{
                                    lineHeight: "14px",
                                    margin: 0,
                                 }}
                              >
                                 {tab.text}
                              </NovaText>
                           </Box>
                        ))}
                     </Box>

                     {isScrolled && (
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "20px",
                           }}
                        >
                           <NovaText
                              type={NovaTextTypes.BaseNormal}
                              style={{
                                 fontStyle: "italic",
                                 color: "#777E90",
                                 margin: 0,
                              }}
                           >
                              <TranslatedText text="personal-memorial-page.share" />
                           </NovaText>

                           <ShareButtons scrolled={isScrolled} />
                        </Box>
                     )}
                  </Box>

                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        marginTop: "20px",
                        paddingBottom: "20px",
                     }}
                  >
                     <Section
                        title={
                           memorialPageData?.isMonumentum
                              ? t("personal-memorial-page.tabs.information")
                              : t("personal-memorial-page.tabs.biography")
                        }
                        id="biography"
                        ref={biographySectionRef}
                     >
                        <MarkdownEditor
                           value={extractTextByLanguage(
                              memorialPageData.biography,
                              i18next.language as Language,
                           ).slice(0, -1)}
                           readOnly
                        />

                        <Box
                           sx={{
                              marginTop: "30px",
                           }}
                        >
                           <FilesList files={memorialPageData.files} />
                        </Box>
                     </Section>

                     <Section
                        title={t("personal-memorial-page.tabs.photos")}
                        id="photos"
                        actions={
                           memorialPageData.photos.length > 0 ? (
                              <NovaButton
                                 style={{
                                    padding: "10px 20px",
                                    fontSize: "14px",
                                    backgroundColor: "transparent",
                                 }}
                                 onClick={onClickSeeAllPhotos}
                              >
                                 <TranslatedText text="personal-memorial-page.all-photos" />
                              </NovaButton>
                           ) : undefined
                        }
                        ref={photosSectionRef}
                     >
                        {memorialPageData.photos.length > 0 ? (
                           <Box
                              sx={{
                                 display: "flex",
                                 flexDirection: "row",
                                 flexWrap: "wrap",
                                 gap: "10px",
                              }}
                           >
                              {memorialPageData.photos.map(
                                 (photo: any, index: number) => (
                                    <Box
                                       sx={{
                                          width: {
                                             xs: "calc(100% / 2 - 10px)",
                                             sm: "calc(100% / 3 - 10px)",
                                             md: "calc(100% / 4 - 10px)",
                                          },
                                          aspectRatio: "1 / 1",
                                          position: "relative",
                                          borderRadius: "12px",
                                          overflow: "hidden",
                                          cursor: "pointer",
                                       }}
                                       key={index}
                                       onClick={() => onClickPhoto(index)}
                                    >
                                       <img
                                          src={photo.photoUrl}
                                          alt=""
                                          style={{
                                             width: "100%",
                                             height: "100%",
                                             objectFit: "cover",
                                          }}
                                       />
                                    </Box>
                                 ),
                              )}
                           </Box>
                        ) : (
                           <NovaText
                              type={NovaTextTypes.MediumNormal}
                              style={{
                                 textAlign: "center",
                                 margin: 0,
                              }}
                           >
                              <TranslatedText text="personal-memorial-page.no-photos" />
                           </NovaText>
                        )}
                     </Section>

                     {!memorialPageData?.isMonumentum && (
                        <Section
                           title={t("personal-memorial-page.tabs.family-tree")}
                           id="familyTree"
                           ref={familyTreeSectionRef}
                        >
                           {memorialPageData?.familyTree.length ? (
                              <Tree data={memorialPageData?.familyTree ?? []} />
                           ) : (
                              <NovaText
                                 type={NovaTextTypes.BaseNormal}
                                 style={{
                                    fontStyle: "italic",
                                    textAlign: "center",
                                 }}
                              >
                                 <TranslatedText text="personal-memorial-page.no-family-tree" />
                              </NovaText>
                           )}
                        </Section>
                     )}

                     <Section
                        title={t("personal-memorial-page.tabs.tributes")}
                        id="tributes"
                        ref={tributesSectionRef}
                     >
                        {memorialPageData.tributes.length > 0 ? (
                           <Box
                              sx={{
                                 display: "flex",
                                 flexDirection: "column",
                                 gap: "20px",
                              }}
                           >
                              {memorialPageData.tributes.map(
                                 (tribute: any, index: number) => (
                                    <Fragment key={index}>
                                       <Divider
                                          style={{
                                             backgroundColor: "#E4CB8D",
                                             margin: "8px 0px",
                                          }}
                                       />
                                       <Tribute
                                          image={tribute.photoUrl}
                                          name={tribute.name}
                                          description={tribute.description}
                                          birthDay={tribute.date}
                                          onClickImage={() => onClickTribute(index)}
                                       />
                                    </Fragment>
                                 ),
                              )}
                           </Box>
                        ) : (
                           <NovaText
                              type={NovaTextTypes.MediumNormal}
                              style={{
                                 textAlign: "center",
                                 margin: 0,
                              }}
                           >
                              <TranslatedText text="personal-memorial-page.no-tributes" />
                           </NovaText>
                        )}

                        <Divider
                           style={{
                              background: "#E4CB8D",
                              margin: "8px 0",
                           }}
                        />
                     </Section>
                  </Box>

                  <Box
                     sx={{
                        position: "sticky",
                        bottom: 0,
                        padding: "20px",
                        zIndex: 999,
                     }}
                  >
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "flex-end",
                        }}
                     >
                        <NovaButton
                           style={{
                              borderRadius: "999px",
                              gap: "10px",
                           }}
                           onClick={() => navigate("/memorial-page/create")}
                        >
                           <AddCircleIcon />
                           <TranslatedText text="buttons.create-your-own" />
                        </NovaButton>
                     </Box>
                  </Box>

                  <Box
                     sx={{
                        paddingBottom: "60px",
                     }}
                  />
               </>
            ) : (
               <Box
                  sx={{
                     marginBlock: {
                        xs: "20px",
                        sm: "60px",
                     },
                  }}
               >
                  <Section>
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           justifyContent: "center",
                           alignItems: "center",
                           gap: "20px",
                        }}
                     >
                        <Loader />
                     </Box>
                  </Section>
               </Box>
            )}
         </Box>

         {photoGalleryIsOpen && (
            <Gallery
               items={memorialPageData?.photos ?? []}
               selectedItemId={selectedPhotoGalleryId}
               handleClose={handleCloseGallery}
            />
         )}

         {tributesGalleryIsOpen && (
            <Gallery
               items={
                  memorialPageData?.tributes.map((tribute: any) => ({
                     photoUrl: tribute.photoUrl,
                     date: tribute.date,
                     id: tribute?.id,
                     title: extractTextByLanguage(
                        tribute.name,
                        i18next.language as Language,
                     ),
                  })) ?? []
               }
               selectedItemId={selectedTributesGalleryId}
               handleClose={handleCloseGallery}
            />
         )}
      </>
   );
}

export default memo(Page);
