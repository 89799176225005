import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Box, Button, Grid } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { UploadIcon } from "../../components/shared/icons/upload-icon";
import NovaTextInput from "../../components/shared/NovaTextInput";
import NovaText, { NovaTextTypes } from "../../components/shared/NovaText";
import EditIcon from "@mui/icons-material/Edit";
import { limitations } from "./limitations";
import { useSelector } from "react-redux";
import { HomeSelectors } from "../home/selectors";
import TranslatedText from "../../components/translated-text";

const GeneralInfo = ({
   memorialPageType,
   info,
   isMonumentum,
   onChange,
}: {
   memorialPageType: string;
   info?: any;
   isMonumentum?: boolean;
   onChange: (event: any, field: any) => void;
}) => {
   const { t } = useTranslation();
   const editMemorialPageData = useSelector(HomeSelectors.getEditedMemorialPage);

   const [pageVisibility, setPageVisibility] = useState(
      info.isPublicPage ? "public" : "private",
   );

   const onFieldChange = (event: any, field: string) => {
      onChange(event.target.value, field);
   };

   const onDateChange = (date: any, field: string) => {
      const formatedDate = dayjs(date).format("YYYY-MM-DD");
      onChange(formatedDate, field);
   };

   const onVisibilityChange = (visibility: string) => {
      setPageVisibility(visibility);
      onChange(visibility === "private" ? false : true, "isPublicPage");
   };

   useEffect(() => {
      setPageVisibility(info?.isPublicPage ? "public" : "private");
   }, [info]);

   return (
      <Grid container spacing={3}>
         <Grid item xs={12} md={6} lg={2}>
            <Dropzone
               accept={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpg"],
               }}
               onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length > 0) {
                     const reader = new FileReader();
                     reader.onload = (e) => {
                        const imageDataUrl = reader.result;
                        onChange(imageDataUrl, "profilePhotoBase64");
                     };
                     reader.readAsDataURL(acceptedFiles[0]);
                  }
               }}
            >
               {({ getRootProps, getInputProps }) => (
                  <Box
                     sx={{
                        maxWidth: "100%",
                        width: "158px",
                        maxHeight: "158px",
                        height: "100%",
                        border: "2px dashed #CAA45D",
                        borderRadius: "16px",
                        cursor: "pointer",
                        overflow: "hidden",
                     }}
                  >
                     <div
                        {...getRootProps()}
                        style={{
                           height: "100%",
                        }}
                     >
                        <input {...getInputProps()} />
                        <div
                           style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                           }}
                        >
                           {info.profilePhotoBase64 && (
                              <Box
                                 sx={{
                                    position: "relative",
                                    "&:hover > .edit-button": {
                                       display: "inline-flex",
                                       background: "#fff",
                                    },
                                 }}
                              >
                                 <Box
                                    sx={{
                                       background: `url(${info.profilePhotoBase64})`,
                                       backgroundSize: "cover",
                                       backgroundRepeat: "no-repeat",
                                       backgroundPosition: "center center",
                                       width: "158px",
                                       height: "158px",
                                    }}
                                 />
                                 <Box
                                    className="edit-button"
                                    sx={{
                                       position: "absolute",
                                       top: "50%",
                                       left: "50%",
                                       transform: "translate(-50%, -50%)",
                                       background: "rgba(255, 255, 255, .8)",
                                       display: "none",
                                       alignItems: "center",
                                       justifyContent: "center",
                                       border: "1px solid #CAA45D",
                                       borderRadius: "50%",
                                       width: "50px",
                                       height: "50px",
                                       color: "#1B1B48",
                                    }}
                                 >
                                    <EditIcon />
                                 </Box>
                              </Box>
                           )}
                           {!info.profilePhotoBase64 && (
                              <Box
                                 sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    border: "1px solid #CAA45D",
                                    borderRadius: "32px",
                                    padding: "10px 24px",
                                 }}
                              >
                                 <UploadIcon />
                                 <TranslatedText text="buttons.upload" />
                              </Box>
                           )}
                        </div>
                     </div>
                  </Box>
               )}
            </Dropzone>
         </Grid>
         <Grid item xs={12} md={6} lg={5}>
            <NovaTextInput
               placeholder={t("create-memorial-page.general.full-name")}
               label={<TranslatedText text="create-memorial-page.general.full-name" />}
               value={info.fullName || ""}
               onChange={(event) => onFieldChange(event, "fullName")}
            />
            {!isMonumentum && (
               <Box sx={{ mt: 4 }}>
                  <NovaTextInput
                     placeholder={t(
                        "create-memorial-page.general.relationship-placeholder",
                     )}
                     label={
                        <TranslatedText text="create-memorial-page.general.relationship" />
                     }
                     value={info.yourRelationship || ""}
                     onChange={(event) => onFieldChange(event, "yourRelationship")}
                  />
               </Box>
            )}
            <Box sx={{ display: "flex", alignItems: "center", mt: 4, gap: 2 }}>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: " column",
                  }}
               >
                  <NovaText
                     type={NovaTextTypes.BaseNormal}
                     style={{
                        margin: 0,
                     }}
                  >
                     {isMonumentum ? (
                        <TranslatedText text="create-memorial-page.general.date" />
                     ) : (
                        <TranslatedText text="create-memorial-page.general.birth-date" />
                     )}
                  </NovaText>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                           value={info.birthDay && dayjs(info.birthDay)}
                           slotProps={{
                              textField: { size: "small" },
                              field: {
                                 clearable: true,
                                 onClear: (event: any) =>
                                    onFieldChange(event, "birthDay"),
                              },
                           }}
                           openTo="year"
                           views={["year", "month", "day"]}
                           minDate={dayjs("01-01-1000")}
                           maxDate={dayjs()}
                           onChange={(event) => onDateChange(event, "birthDay")}
                        />
                     </DemoContainer>
                  </LocalizationProvider>
               </Box>
               {(memorialPageType === "online-memorial" || info.deathDay) && (
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: " column",
                     }}
                  >
                     <NovaText
                        type={NovaTextTypes.BaseNormal}
                        style={{
                           margin: 0,
                        }}
                     >
                        <TranslatedText text="create-memorial-page.general.dead-date" />
                     </NovaText>
                     <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                           <DatePicker
                              value={info.deathDay && dayjs(info.deathDay)}
                              slotProps={{
                                 textField: { size: "small" },
                                 field: {
                                    clearable: true,
                                    onClear: (event: any) =>
                                       onFieldChange(event, "deathDay"),
                                 },
                              }}
                              openTo="year"
                              views={["year", "month", "day"]}
                              minDate={dayjs("01-01-1000")}
                              maxDate={dayjs()}
                              onChange={(event) => onDateChange(event, "deathDay")}
                           />
                        </DemoContainer>
                     </LocalizationProvider>
                  </Box>
               )}
            </Box>

            <Box
               sx={{
                  marginTop: "32px",
               }}
            >
               <NovaText type={NovaTextTypes.BaseNormal}>
                  <TranslatedText text="create-memorial-page.general.page-visibility.title" />
               </NovaText>
               <Box sx={{ display: "flex" }}>
                  <Box
                     sx={{
                        width: "200px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        position: "relative",
                        background: "#fff",
                        border: "1px solid #1B1B48",
                     }}
                  >
                     <Box
                        sx={{
                           width: "100px",
                           height: "38px",
                           borderRadius: "20px",
                           background: "#1B1B48",
                           position: "absolute",
                           transition: "all 0.5s ease",
                           transform: `translateX(${
                              pageVisibility === "private" ? 0 : "100px"
                           })`,
                        }}
                     />
                     <Button
                        disableRipple
                        variant="text"
                        sx={{
                           flex: 1,
                           color: pageVisibility === "private" ? "#CAA45D" : "#1B1B48",
                           ".MuiButton-root": {
                              borderRadius: "20px",
                              width: "100px",
                              height: "40px",
                              fontWeight: "bold",
                              transition: "all 0.2s 0.1s ease",
                           },
                           ".MuiButton-root:hover": {
                              opacity: "0.8",
                           },
                        }}
                        onClick={() => onVisibilityChange("private")}
                     >
                        <TranslatedText text="create-memorial-page.general.page-visibility.private" />
                     </Button>
                     <Button
                        disableRipple
                        variant="text"
                        sx={{
                           flex: 1,
                           color: pageVisibility === "public" ? "#CAA45D" : "#1B1B48",
                           ".MuiButton-root": {
                              borderRadius: "20px",
                              width: "100px",
                              height: "40px",
                              fontWeight: "bold",
                              transition: "all 0.2s 0.1s ease",
                           },
                           ".MuiButton-root:hover": {
                              opacity: "0.8",
                           },
                        }}
                        onClick={() => onVisibilityChange("public")}
                     >
                        <TranslatedText text="create-memorial-page.general.page-visibility.public" />
                     </Button>
                  </Box>
               </Box>
            </Box>
         </Grid>
         <Grid item xs={12} md={6} lg={5}>
            <NovaTextInput
               placeholder={t("create-memorial-page.general.nickname")}
               label={<TranslatedText text="create-memorial-page.general.nickname" />}
               value={info.nickName || ""}
               onChange={(event) => onFieldChange(event, "nickName")}
            />
            <Box sx={{ mt: 4 }}>
               <NovaTextInput
                  placeholder={
                     isMonumentum
                        ? t("create-memorial-page.general.place")
                        : t("create-memorial-page.general.birth-place")
                  }
                  label={
                     isMonumentum ? (
                        <TranslatedText text="create-memorial-page.general.place" />
                     ) : (
                        <TranslatedText text="create-memorial-page.general.birth-place" />
                     )
                  }
                  value={info.city || ""}
                  onChange={(event) => onFieldChange(event, "city")}
               />
            </Box>
            <Box sx={{ mt: 4 }}>
               <NovaText
                  type={NovaTextTypes.BaseNormal}
                  style={{
                     margin: 0,
                  }}
               >
                  {memorialPageType === "online-memorial" ? (
                     <TranslatedText text="create-memorial-page.general.brief-tribute" />
                  ) : (
                     <TranslatedText text="create-memorial-page.general.thought" />
                  )}
               </NovaText>
               <NovaTextInput
                  fullwidth
                  rows={3}
                  multiline
                  value={info.briefTribute}
                  placeholder={
                     memorialPageType === "online-memorial"
                        ? t("create-memorial-page.general.brief-tribute-placeholder")
                        : t("create-memorial-page.general.thought-placeholder")
                  }
                  inputProps={{
                     maxLength:
                        limitations[
                           editMemorialPageData?.subscription
                              ? editMemorialPageData.subscription?.subscriptionPlan?.type
                              : "free"
                        ]?.briefTributeLength,
                  }}
                  onChange={(event) => onFieldChange(event, "briefTribute")}
               />
            </Box>
         </Grid>
      </Grid>
   );
};

export default GeneralInfo;
