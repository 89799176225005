import { Language } from "../translations/i18n";

export const getMonthName = (monthIndex: number, shortName?: boolean): string => {
   return [
      shortName ? "Jan" : "January",
      shortName ? "Feb" : "February",
      shortName ? "Mar" : "March",
      shortName ? "Apr" : "April",
      shortName ? "May" : "May",
      shortName ? "Jun" : "June",
      shortName ? "Jul" : "July",
      shortName ? "Aug" : "August",
      shortName ? "Sep" : "September",
      shortName ? "Oct" : "October",
      shortName ? "Nov" : "November",
      shortName ? "Dec" : "December",
   ][monthIndex];
};

export const checkEmailIsValid = (email: string) => {
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailRegex.test(email);
};

export const extractTextByLanguage = (text: string, language: Language) => {
   const bgRegex = /\[bg\](.*?)\[\/bg\]/;
   const enRegex = /\[en\](.*?)\[\/en\]/;
   const usRegex = /\[us\](.*?)\[\/us\]/;

   switch (language) {
      case "bg": {
         const match = bgRegex.exec(text);
         return match ? match[1].trim() : text;
      }
      case "en": {
         const match = enRegex.exec(text);
         return match ? match[1].trim() : text;
      }
      case "us": {
         const match = usRegex.exec(text);
         return match ? match[1].trim() : text;
      }

      default:
         return text;
   }
};
